<template>
  <v-container class="white d-flex flex-column align-center py-14">
    <v-row
      ><v-col><v-icon color="error" dark size="100px">mdi-close-circle</v-icon></v-col></v-row
    >
    <v-row
      ><v-col><h1 class="text-center">404</h1></v-col>
    </v-row>
    <v-row
      ><v-col
        ><p class="text-center">Ścieżka, do której próbujesz się dostać nie istnieje.</p></v-col
      >
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'NotFound',
};
</script>
